<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12 class="overlay">
        <v-card outlined class="primary--border">
          <!-- Title for the page -->
          <v-card-title class="title">
            Certificate Template
            <v-spacer></v-spacer>
            <add-button
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              :permission="true"
              >Add New Template</add-button
            >  
          </v-card-title>
          <!-- Title for the page -->

          <!-- Table for the page -->
          <v-data-table
            :headers="headers"
            hide-default-footer
            :options.sync="pagination"
            :items="form.items.data"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.template_name }}
                  <span v-if="item.id === defaultTemplate.id"
                    ><v-icon color="green" size="18px">check_circle</v-icon></span
                  >
                  <br />
                  <span
                    style="font-size: 10px; color: #666"
                    v-if="item.id === defaultTemplate.id"
                  >
                  Used as default
                  </span>
                </td>
                <!-- <td class="text-xs-left">
                   {{ item.content }}
                </td> -->
                <td class="text-xs-left">
                  {{ item.title }}
                </td>
                <td class="text-right">
                  <edit-button
                    v-if="item.is_default === false"
                    permission="grade-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    v-if="item.is_default === false"
                    permission="grade-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- Table for the page -->
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="form.dialog" scrollable persistent max-width="1000px">
      <v-card outlined class="primary--border">
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text>
          <br />

          <v-form ref="form" @submit.prevent="save" @keyup.enter="save">
            <div class="mt-2">
              <v-row>
                <v-col xs-12 sm-6>
                  <v-text-field
                    autocomplete="off"
                    label="Template Name*"
                    required
                    class="pa-0"
                    outlined
                    dense
                    v-model="form.template_name"
                    name="name"
                    :error-messages="form.errors.get('name')"
                    :rules="[(v) => !!v || 'Name is required']"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    autocomplete="off"
                    label="Title*"
                    required
                    class="pa-0"
                    outlined
                    dense
                    v-model="form.title"
                    name="name"
                    :error-messages="form.errors.get('name')"
                    :rules="[(v) => !!v || 'Name is required']"
                  />
                </v-col>
              </v-row>
              <v-template-creator-field
                :rows="8"
                :show-alert="false"
                v-model="form.content"
                label="Certificate Content"
                :default-placeholder="[
                  {
                    text: 'Student Name',
                    placeholder: '[[student_name]]',
                  },
                  {
                    text: 'Father Name',
                    placeholder: '[[father_name]]',
                  },
                  {
                    text: 'Mother Name',
                    placeholder: '[[mother_name]]',
                  },
                  {
                    text: 'District',
                    placeholder: '[[district]]',
                  },
                  {
                    text: 'Address',
                    placeholder: '[[address]]',
                  },
                  {
                    text: 'Ward No',
                    placeholder: '[[ward_no]]',
                  },
                  {
                    text: 'From Date',
                    placeholder: '[[from_date]]',
                  },
                  {
                    text: 'To Date',
                    placeholder: '[[to_date]]',
                  },
                  {
                    text: 'Grade',
                    placeholder: '[[grade]]',
                  },
                  {
                    text: 'GPA',
                    placeholder: '[[gpa]]',
                  },
                  {
                    text: 'Exam Date BS',
                    placeholder: '[[exam_date_BS]]',
                  },
                  {
                    text: 'Exam Date AD',
                    placeholder: '[[exam_date_AD]]',
                  },
                  {
                    text: 'Date Of Birth(BS)',
                    placeholder: '[[date_of_birth_BS]]',
                  },
                  {
                    text: 'Date Of Birth(AD)',
                    placeholder: '[[date_of_birth_AD]]',
                  },
                ]"
                :default-content="form.content"
              ></v-template-creator-field>

              <v-flex xs12>
                <v-checkbox
                  style="margin-right: 20px"
                  color="blue"
                  v-model="form.use_as_default"
                >
                  <template v-slot:label>
                    <div style="color: #333">
                      Default Template
                      <p style="line-height: 10px">
                        <small style="color: #666; font-size: 10px">
                          Check if you want to use this as the default template
                          for every certificate prints.
                        </small>
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </v-flex>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(form.dialog = false), form.reset()"
            >Close</v-btn
          >
          <v-btn
            @click="save"
            :disabled="false"
            color="success"
            :loading="false"
            text
            >Save
          </v-btn>

          <!--<load-button  @action="store" color="success" text >Save</load-button>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";
import AddButton from "../../../../components/AddButton.vue";

export default {
  components: { AddButton },
  mixins: [Mixins],

  data: () => ({
    defaultTemplate:{},
    form: new Form(
      {
        template_name: "",
        title: "",
        content: "",
        use_as_default: "",
      },
      "/api/certificate-template"
    ),
    createCertificateModal: false,
    headers: [
      { text: "SN", align: "left", value: "id", width: 50, sortable: false },
      {
        text: "Template Name",
        align: "left",
        value: "subject",
        sortable: false,
        width: 280,
      },

      {
        text: "Title",
        align: "left",
        value: "end_time",
        sortable: false,
        width: 300,
      },
      {
        text: "Action",
        align: "right",
        value: "end_time",
        sortable: false,
        width: 150,
      },
    ],
  }),
  watch: {
    "form.exam_original_name": function (value) {
      if (!this.form.template_name || !this.form.id) {
        this.form.template_name = value;
      }
    },
  },
  computed: {},
  mounted() {
    this.get();
  },
  methods: {
    get() {
      
      this.form.get().then((res)=>{
        this.defaultTemplate={};
        res.data.data.map((item)=>{
          if(item.use_as_default === true || item.is_default===true){
            this.defaultTemplate = item;
          }
        })
      });
    },

    save() {
      this.form.fireFetch=false;
      this.form.store().then((res)=>{
        this.get();
      });
    },
  },
};
</script>

